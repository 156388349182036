import React, { useContext ,useEffect} from 'react';
import {  useNavigate} from 'react-router-dom';

import MiContexto from '../MiContexto'

import DemoDevice from './DemoDevice';
import BuyS1 from './BuyStrategies/BuyS1';
import BuyS2 from './BuyStrategies/BuyS2';
import BuyS3 from './BuyStrategies/BuyS3';
import { use } from 'react';

export default function Buy(props) {


    const { currentMobile, demoMode } = useContext(MiContexto)
    const navigate = useNavigate();

    //currentMobile=null  Cuando vuelvo hacia atras desde la pasarela de pagos
    useEffect(() => {
        if (!currentMobile) 
            navigate('/')
    }, [currentMobile])



    if (!demoMode) return <DemoDevice mobile={currentMobile} />
    // if (!currentMobile) return <BuyS1 mobile={currentMobile} /> 

    let country=currentMobile?.originalCountry
 

    // if (country === "co" ||  country === "mx" ||  country === "pe"  ||  
    //     country === "cl" ||  country === "cr" ||  country === "ni" ||  
    //     country === "br"  ||  country === "sv" ||  country === "do" ||  
    //     country === "py") {
    //     return (
    //         <BuyS3 mobile={currentMobile} products={props.products} getPayUrl={props.getPayUrl} />
    //     )
    // } else {
        return (
            <BuyS1 mobile={currentMobile} />
            // <BuyS2 mobile={currentMobile} />
            // <BuyS3 mobile={currentMobile} products={props.products}  getPayUrl={props.getPayUrl}/>
        )

    // }




}




