import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';

import './App.css';
import axios from "axios";

import MiContexto from './MiContexto'
import Cookies from 'js-cookie';

import Dashboard from './Components/Dashboard';
import Header from './Components/Header';
import SearchPhone from './Components/SearchPhone';
import HowToUse from './Components/HowToUse';
import Support from './Components/Support';
import MobileWithPayment from './Components/MobileWithPayment';
import ServiceStatus from './Components/ServiceStatus';

import Buy from './Components/Buy';

import { getRandomNumber, getRandomEmail } from './Components/Features/functions';

import Footer from "./Components/Footer";

//Componente Ads
import MyAds from "./MyAds";


import { URL_BACKEND, COOKIE_USER, COOKIE_USER_CREATED, COOKIE_QUOTA, MAX_MOBILES, QUOTA_EXPIRES, COOKIE_MOBILES } from "./config";
import { set } from 'date-fns';
import DemoDevice from './Components/DemoDevice';


function App() {


  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState(null);
  const [currentMobile, setCurrentMobile] = useState(null);
  const [mobiles, setMobiles] = useState([]);
  const [products, setProducts] = useState(null);

  const [adsMode, setAdsMode] = useState(true)
  const [demoMode, setDemoMode] = useState(true)

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    let current=Cookies.get('unlocked')
    if(current==='true')setDemoMode(false)
    
    //removeAllCookies()
    loadUserFromCookie()
    loadMobilesFromCookie()
    loadProducts()

    //if (document.URL.includes('.com')) setAdsMode(true)

    const isFirstLoadFromCookie = Cookies.get(COOKIE_USER_CREATED)
    if (!isFirstLoadFromCookie) {
      Cookies.set(COOKIE_USER_CREATED, "true", { expires: 30 });
      navigate('/searchPhone')
    }
  }, [])




  const loadPayment = async (number) => {
    return axios.get(`${URL_BACKEND}/products/getPayment/${number}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });
  }


  const loadProducts = async () => {

    await axios.get(`${URL_BACKEND}/products/`)
      .then(response => {
        if (response.data.code != 'ERR') {
          //console.log(response.data)
          setProducts(response.data)
        }
      })
      .catch(e => {
        console.log("Error", e)
      });

  }

  // const loadQuoteFromCookie = () => {
  //   //LEVANTA UNA CUOTA DE LA COOKIE Y DE NO EXISTIR CREA UNA NUEVA
  //   const cookie_quota = Cookies.get(COOKIE_QUOTA);
  //   if (cookie_quota) {
  //     console.log("Cuota en la Cookie", cookie_quota)
  //     const currentQuota = JSON.parse(cookie_quota);
  //     setQuotaExceeded(currentQuota)
  //   }
  // }

  // const startNewQuota = () => {
  //   const newQuota = { expiresTime: Date.now() + QUOTA_EXPIRES }
  //   setQuotaExceeded(newQuota)
  //   Cookies.set(COOKIE_QUOTA, JSON.stringify(newQuota), { expires: new Date(newQuota.expiresTime) });
  // }


  const loadUserFromCookie = () => {
    //LEVANTA UN USUARIO DE LA COOKIE Y DE NO EXISTIR CREA UNO RANDOM
    //Se necesita obligatoriamente para BUY.JSX
    const cookie_usuario = Cookies.get(COOKIE_USER);
    if (cookie_usuario) {
      //console.log("Usuario en la Cookie", cookie_usuario)
      const currentUser = JSON.parse(cookie_usuario);
      setUser(currentUser)
    } else {
      let aUser = { id: getRandomNumber(1000, 9999), email: getRandomEmail(), pass: getRandomNumber(1000000, 9999999) }
      setUser(aUser)
      Cookies.set(COOKIE_USER, JSON.stringify(aUser), { expires: 30 });
    }
  }

  const saveMobileOnCookie = (newMobile) => {
    let mobiles = Cookies.get(COOKIE_MOBILES)
    if (mobiles) {
      mobiles = JSON.parse(mobiles)
      mobiles.push(newMobile)
    } else {
      mobiles = [newMobile]
    }
    Cookies.set(COOKIE_MOBILES, JSON.stringify(mobiles), { expires: 30 });
  }

  const removeMobile = async (aMobile) => {
    //Elimino de la COOKIE y del BACKEND
    console.log("removeMobile ", aMobile.number)

    let cookieMobiles = Cookies.get(COOKIE_MOBILES)
    if (cookieMobiles) {
      let mobilesFromCookie = JSON.parse(cookieMobiles)
      mobilesFromCookie = mobilesFromCookie.filter(mobile => mobile.number != aMobile.number)
      Cookies.set(COOKIE_MOBILES, JSON.stringify(mobilesFromCookie), { expires: 30 });
    }
    await axios.put(`${URL_BACKEND}/mobile/resetExpires`, { code: aMobile.code, number: aMobile.number })
  }

  const loadMobilesFromCookie = async () => {
    let cookieMobiles = Cookies.get(COOKIE_MOBILES)
    if (cookieMobiles) {
      let mobilesFromCookie = JSON.parse(cookieMobiles)
      console.log("mobiles", mobilesFromCookie)

      let mobilesToDelete = []

      //Busco si tiene algun pago realizado y si esta caducado
      setIsLoading(true)
      for (let i = 0; i < mobilesFromCookie.length; i++) {
        const resp = await loadPayment(mobilesFromCookie[i].number)
        mobilesFromCookie[i].payment = resp

        if (mobilesFromCookie[i].expires < Date.now()) mobilesToDelete.push(mobilesFromCookie[i])
      }
      setIsLoading(false)
      //-------------------------

      if (mobilesToDelete.length > 0) {
        for (let i = 0; i < mobilesToDelete.length; i++) {
          await removeMobile(mobilesToDelete[i])
          mobilesFromCookie = mobilesFromCookie.filter(mobile => mobile.number != mobilesToDelete[i].number)
        }
      }

      setMobiles(mobilesFromCookie)
    }
  }

  const removeAllCookies = () => {
    Cookies.remove(COOKIE_USER);
    Cookies.remove(COOKIE_QUOTA);
    Cookies.remove(COOKIE_MOBILES);
  }


  const handleNewMobile = async (newMobile) => {

    newMobile.expires = Date.now() + QUOTA_EXPIRES

    return axios.post(`${URL_BACKEND}/mobile/`, newMobile)
      .then(response => {


        if (response.data.code == 'OK' || response.data.code == 'DUP') {


          if (response.data.code == 'DUP') {
            newMobile.expires = response.data.mobile.expires
            newMobile.searchingTimes = response.data.mobile.searchingTimes
            newMobile.dateLastSearch = response.data.mobile.dateLastSearch
            newMobile.payment = response.data.mobile.payment
          }


          console.log("nuevo movil", newMobile)


          saveMobileOnCookie(newMobile)
          let mobilesAux = mobiles
          mobilesAux.push(newMobile)
          setMobiles(mobilesAux)
        }


        // if (response.data.code == 'OK') {   //No esta registrado
        //   let mobilesAux = mobiles
        //   mobilesAux.push(newMobile)
        //   setMobiles(mobilesAux)
        // } else if (response.data.code == 'DUP') {    //Ya esta registrado
        //   loadMobilesFromCookie()
        // } else {
        //   //Error
        //   setWarning(response.data.message)
        // }



        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });

  }



  const getPayUrl = async (userId, email, number, country,productId) => {

    return axios.post(`${URL_BACKEND}/products/getBuyUrl/`, { userId: userId, email: email, number: number, country:country, productId: productId })
      .then(response => {
        console.log("getPayUrl: ", response.data)
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });


  }

  const handleBuy = (mobile) => {
    setCurrentMobile(mobile)
    navigate('/buy')
  }

  const handleSupportQuery = async (query) => {

    console.log("handleSupportQuery: ", query)
    return axios.put(`${URL_BACKEND}/user/newQuery/`, query)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log("Error", e)
        return e.message
      });
  }


  return (
    <React.Fragment>
      <MiContexto.Provider value={{
        user: user,
        adsMode: adsMode,
        demoMode: demoMode,
        // quotaExceeded: quotaExceeded,
        currentMobile: currentMobile,
        mobiles: mobiles,
      }}>

        <div className='main'>
          {/* <video autoPlay loop muted src={"./images/videoFondo2.mp4"} /> */}
          <div className=' overlay'>


            <div className="App" style={{ paddingTop: "50px", paddingBottom: "50px" }}>


              <div class='light x1'></div>
              <div class='light x2'></div>

              <div class='light x5'></div>
              <div class='light x6'></div>
              <div class='light x7'></div>
              <div class='light x8'></div>
              <div class='light x9'></div>


              <Header handleBuy={handleBuy} />


              <Routes>
                <Route path="/" element={
                  <Dashboard handleBuy={handleBuy} isLoading={isLoading} loadMobilesFromCookie={loadMobilesFromCookie} />
                } />

                <React.Fragment>
                  <Route path="dashboard" element={<Dashboard handleBuy={handleBuy}  isLoading={isLoading} loadMobilesFromCookie={loadMobilesFromCookie} />} />
                  <Route path="searchPhone" element={<SearchPhone handleNewMobile={handleNewMobile} handleBuy={handleBuy} />} />
                  <Route path="buy" element={<Buy products={products} getPayUrl={getPayUrl} />} />
                  {/* <Route path="buy2" element={<Buy products={products} getPayUrl={getPayUrl} />} /> */}
                  <Route path="howtouse" element={<HowToUse />} />
                  <Route path="support" element={<Support handleSupportQuery={handleSupportQuery} />} />
                  <Route path="getMobile" element={<MobileWithPayment />} />
                  <Route path="status" element={<ServiceStatus />} />
                </React.Fragment>


              </Routes>


              {/* <MyAds /> */}

            </div>
          </div>
        </div>
        <Footer />
      </MiContexto.Provider>
    </React.Fragment>
  );
}


export default App;
